import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PurchaseModals from "../../components/PurchaseModals";
import BuyButton from "../../components/BuyButton";
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { db } from "../../firebase";
import { ENVIRONMENT } from "../../globalVariables";

const CollectionCotentin = ({ data }) => {
  const { artistId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [randomArtwork, setRandomArtwork] = useState(null);

  useEffect(() => {
    async function getRandomAvailableArtwork() {
      function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      const minTokenId = ENVIRONMENT === "prod" ? 4495 : 14;
      const maxTokenId = ENVIRONMENT === "prod" ? 4997 : 34;

      let artwork = null;
      let attempts = 0;

      while (!artwork && attempts < 498) {
        const randomTokenId = getRandomNumber(
          minTokenId,
          maxTokenId
        ).toString();

        const artworksRef = collection(db, "artworks");
        const q = query(
          artworksRef,
          where("tokenId", "==", randomTokenId),
          where("soldOut", "==", false),
          limit(1)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          artwork = querySnapshot.docs[0].data();
        }

        attempts++;
      }

      if (artwork) {
        setRandomArtwork(artwork);
        setIsLoading(false);
        console.log("Available artwork:", artwork.tokenId);
        return artwork;
      } else {
        console.log("No available artwork.");
        return null;
      }
    }

    if (artistId) {
      getRandomAvailableArtwork();
    }
  }, [artistId]);

  return (
    <div className="pr-6 md:pr-8 pt-5 md:pt-8 pb-10 space-y-16">
      {isLoading ? (
        <div className="flex w-full justify-center items-center h-64">
          <CircularProgress
            sx={{ color: "#33c7b6" }}
            style={{ height: 60, width: 60 }}
          />
        </div>
      ) : (
        <>
          {data?.map((collection, index) => (
            <div
              key={index}
              className="md:flex md:space-x-10 lg:space-x-16 xl:space-x-20 items-center"
            >
              <div className="md:max-w-[370px] lg:max-w-[460px] xl:max-w-[550px] text-white space-y-6">
                <div className="space-y-2 lg:space-y-4">
                  <p className="text-[35px] lg:text-[44px] leading-[35px] lg:leading-[44px] font-medium">
                    {collection?.name}
                  </p>

                  <div className="flex text-[18px] lg:text-[28px] font-medium space-x-4 lg:space-x-20">
                    <p className="">
                      {collection?.nbOfArtworks +
                        (collection?.nbOfArtworks == 1 ? " NFT" : " NFTs")}
                    </p>

                    <p className="">{collection?.yearOfProd}</p>
                  </div>
                </div>

                <img
                  className="flex md:hidden object-cover w-full max-h-[300px]"
                  src={collection?.coverPicture}
                  alt=""
                />

                <p
                  className={`text-base lg:text-xl`}
                  dangerouslySetInnerHTML={{
                    __html: collection?.description,
                  }}
                />

                <div className="flex w-full justify-center pt-2 lg:pt-4">
                  <BuyButton
                    artworkData={randomArtwork}
                    design="primary"
                    text="Acheter un badge"
                  />
                </div>
              </div>

              <div className="hidden md:flex w-full">
                <img
                  className="object-cover w-full max-h-[400px]"
                  src={collection?.coverPicture}
                  alt=""
                />
              </div>
            </div>
          ))}
        </>
      )}

      <PurchaseModals />
    </div>
  );
};

export default CollectionCotentin;
