import React, { useState } from "react";
import usePurchase from "../hooks/usePurchase";

const BuyButton = ({ artworkData, design, text = "Buy now" }) => {
  const { setCurrentPurchasedArtwork, buyNow } = usePurchase();

  const handleSubmit = () => {
    setCurrentPurchasedArtwork(artworkData);
    buyNow();
  };

  return (
    <>
      {artworkData?.artworkSizeSoldOut ? (
        <button
          className="text-[#33c7b6] border-2 border-[#33c7b6] py-2 px-6 rounded-full flex-none"
          disabled
        >
          Sold Out
        </button>
      ) : (
        <button
          className={`font-source py-2 px-6 rounded-full cursor-pointer flex-none ${
            design === "primary"
              ? "text-[#00263a] bg-white"
              : "text-[#33c7b6] bg-transparent border-2 border-[#33c7b6]"
          }`}
          onClick={handleSubmit}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default BuyButton;
