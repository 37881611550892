import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractRead,
} from "wagmi";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import TheGalleryMintAbi from "../json/TheGalleryMint.json";
import MerchantAbi from "../json/Merchant.json";
import {
  SERVER_URL,
  TG_APP_URL,
  SM_MERCHANT_ADDRESS,
  SM_MINT_ADDRESS,
  SM_SALE_ADDRESS,
  TG_WALLET_ADDRESS,
  VAT_WALLET,
  chainId,
  chainIdHex,
  cardServiceFees,
} from "../globalVariables";
import { useExchangeRate } from "../hooks/usePriceConversion";

function useRefAuth() {
  const auth = useAuth();
  const authRefs = useRef({
    currentUser: null,
    moreInfoCurrentUser: null,
    setOpenConnexionModal: null,
    setBuyNowActive: null,
    setOpenPaymentModal: null,
    VerifyMetamaskExtension: null,
    setOpenSwitchNetworkModal: null,
    setOpenConfirmEmailModal: null,
    setOpenVerifyMetamaskExtensionModal: null,
    ResendEmailVerification: null,
  });

  useEffect(() => {
    Object.keys(auth).forEach((key) => {
      authRefs.current[key] = auth[key];
    });
  }, [auth]);

  return authRefs.current;
}

const purchaseContext = createContext({});

export function PurchaseProvider({ children }) {
  const auth = useRefAuth();
  const [currentPurchasedArtwork, setCurrentPurchasedArtwork] = useState(null);
  const [openIsPendingModal, setOpenIsPendingModal] = useState(false);
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openReservedErrorModal, setOpenReservedErrorModal] = useState(false);
  const [wagmiState, setWagmiState] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const location = useLocation();
  const {
    totalPriceWithVAT,
    PriceInEuro,
    VATPrice,
    convertWeiToMatic,
    convertMaticToWei,
    exchangeRate,
  } = useExchangeRate();
  const queryParams = new URLSearchParams(location.search);
  const tokenId = queryParams.get("tokenId");
  const success = queryParams.get("success");
  const canceled = queryParams.get("canceled");

  useEffect(() => {
    if (currentPurchasedArtwork) {
      setWagmiState(true);
    }
  }, [currentPurchasedArtwork]);

  const contractRead = useContractRead({
    chainId: chainId,
    address: SM_MINT_ADDRESS,
    abi: TheGalleryMintAbi,
    functionName: "ownerOf",
    enabled: wagmiState,
    args: [currentPurchasedArtwork?.tokenId],
  });

  useEffect(() => {
    const updateOwner = async () => {
      await updateArtworkOwner(
        currentPurchasedArtwork.tokenId,
        contractRead?.data,
        "moralis"
      );
      refreshPage();
    };

    if (
      currentPurchasedArtwork &&
      contractRead?.data &&
      contractRead?.data !== SM_SALE_ADDRESS &&
      currentPurchasedArtwork?.ownerOf === SM_SALE_ADDRESS
    ) {
      updateOwner();
    }
  }, [contractRead, currentPurchasedArtwork]);

  const totalPriceCotentin = convertMaticToWei(60 / Number(exchangeRate?.rate));

  const { config } = usePrepareContractWrite({
    address: SM_MERCHANT_ADDRESS,
    chainId: chainId,
    abi: MerchantAbi,
    functionName: "payTransaction",
    args: [
      SM_MINT_ADDRESS,
      currentPurchasedArtwork?.tokenId,
      VAT_WALLET,
      currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
        ? (
            totalPriceCotentin - Number(currentPurchasedArtwork?.price)
          ).toString()
        : convertMaticToWei(
            VATPrice(
              convertWeiToMatic(currentPurchasedArtwork?.price),
              currentPurchasedArtwork?.artworkSize
            )
          ),
    ],
    overrides: {
      value:
        currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
          ? totalPriceCotentin
          : convertMaticToWei(
              totalPriceWithVAT(
                currentPurchasedArtwork?.price,
                currentPurchasedArtwork?.artworkSize,
                "MATIC"
              )
            ),
      gasLimit: 450000,
    },
    enabled: wagmiState,
  });

  const { data, error, write } = useContractWrite(config);

  const { isLoading } = useWaitForTransaction({
    chainId: chainId,
    hash: data?.hash,
    async onSuccess(data) {
      await updateArtworkOwner(
        currentPurchasedArtwork.tokenId,
        auth.moreInfoCurrentUser?.walletAddress,
        "moralis"
      );
      setOpenIsPendingModal(false);
      setOpenSuccessfulModal(true);
    },
    async onError(error) {
      await deleteReservation(0, currentPurchasedArtwork?.tokenId);
      setOpenIsPendingModal(false);
      setOpenErrorModal(true);
      console.log("Error", error);
    },
  });

  useEffect(() => {
    const checkTransactionStatus = async () => {
      if (isLoading) {
        setOpenIsPendingModal(true);
      } else if (error) {
        if (error.code === 4001) {
          await deleteReservation(0, currentPurchasedArtwork?.tokenId);
        } else {
          setOpenErrorModal(true);
        }
      }
    };

    checkTransactionStatus();
  }, [isLoading, error]);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const buyNow = () => {
    if (!auth.currentUser) {
      auth.setOpenConnexionModal(true);
      auth.setBuyNowActive(true);
    } else if (
      !auth.currentUser?.emailVerified &&
      auth.moreInfoCurrentUser?.provider === "firebase"
    ) {
      auth.setOpenConfirmEmailModal(true);
      auth.ResendEmailVerification();
      auth.setBuyNowActive(true);
    } else {
      auth.setOpenPaymentModal(true);
    }
  };

  const buyArtworkByWallet = async () => {
    if (!termsAndConditions) {
      setTermsError(true);
    } else if (auth.currentUser && termsAndConditions) {
      if (window.ethereum === undefined || !window.ethereum.isMetaMask) {
        if (isBrowser) {
          return auth.setOpenVerifyMetamaskExtensionModal(true);
        } else {
          window.location.replace(
            `https://metamask.app.link/dapp/${TG_APP_URL}`
          );
        }
      } else if (
        auth.moreInfoCurrentUser?.provider === "moralis" &&
        window.ethereum.chainId !== chainIdHex
      ) {
        auth.setOpenSwitchNetworkModal(true);
      } else {
        const tokenId = currentPurchasedArtwork?.tokenId;
        const artworkRef = doc(db, "reservations", tokenId);
        const artworkSnap = await getDoc(artworkRef);

        if (artworkSnap.exists()) {
          setOpenReservedErrorModal(true);
        } else {
          await setDoc(artworkRef, {
            tokenId: tokenId,
            state: "pending",
            createTime: serverTimestamp(),
          })
            .then(async () => {
              deleteReservation(300000, tokenId);
              write();
            })
            .catch((error) => {
              setOpenErrorModal(true);
              console.log(error.code);
            });
        }
      }
    } else {
      auth.VerifyMetamaskExtension();
    }
  };

  const updateArtworkOwner = async (tokenId, owner, provider) => {
    try {
      let params = {
        tokenId: tokenId,
        newOwner: owner,
        userProvider: provider,
      };

      if (provider === "firebase") {
        params["ownerUid"] = auth.currentUser?.uid;
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };

      let res = await fetch(`${SERVER_URL}/api/updateArtworkOwner`, options);
      res = await res.json();

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReservation = async (timer, tokenId) => {
    try {
      const data = { tokenId: tokenId, timer: timer };
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const res = await fetch(`${SERVER_URL}/api/deleteReservation`, options)
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const buyArtworkByCard = async () => {
    if (termsAndConditions && auth.currentUser) {
      const tokenId = currentPurchasedArtwork?.tokenId;
      const artworkRef = doc(db, "reservations", tokenId);
      const artworkSnap = await getDoc(artworkRef);

      if (artworkSnap.exists()) {
        setOpenReservedErrorModal(true);
      } else {
        await setDoc(artworkRef, {
          tokenId: tokenId,
          state: "pending",
          createTime: serverTimestamp(),
        })
          .then(async () => {
            deleteReservation(300000, tokenId);

            try {
              const baseUrl = window.location.origin + window.location.pathname;
              const price = PriceInEuro(
                convertWeiToMatic(currentPurchasedArtwork?.price)
              );

              let params = {
                tokenId: tokenId,
                artworkName: currentPurchasedArtwork?.name,
                price:
                  currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
                    ? "50"
                    : price,
                vat:
                  currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
                    ? "10"
                    : VATPrice(
                        price,
                        currentPurchasedArtwork?.artworkSize
                      ).toFixed(2),
                cardServiceFees:
                  currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
                    ? "1"
                    : totalPriceWithVAT(
                        currentPurchasedArtwork?.price,
                        currentPurchasedArtwork?.artworkSize,
                        "EUR"
                      ) *
                      (cardServiceFees / 100),
                successUrl: `${baseUrl}?tokenId=${tokenId}&success=true`,
                cancelUrl: `${baseUrl}?tokenId=${tokenId}&canceled=true`,
              };

              let options = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(params),
              };

              let res = await fetch(
                `${SERVER_URL}/api/createCheckoutSession`,
                options
              );
              res = await res.json();

              if (res?.id) {
                const artworkRef = doc(db, "artworks", tokenId);
                const checkoutSessionId = res?.id;
                await updateDoc(artworkRef, {
                  checkoutSessionId,
                });
                localStorage.setItem(
                  "currentPurchasedData",
                  JSON.stringify({
                    ...currentPurchasedArtwork,
                    checkoutSessionId,
                  })
                );

                window.location.href = res?.url;
              } else {
                throw new Error("Error when creating checkout session");
              }
            } catch (error) {
              console.log(error);
            }
          })
          .catch((error) => {
            setOpenErrorModal(true);
            console.log(error.code);
          });
      }
    } else {
      setTermsError(true);
    }
  };

  const getCheckoutSession = async (uid) => {
    try {
      if (!uid) {
        throw new Error("Uid parameter is required.");
      }

      let options = {
        headers: {
          Accept: "application/json",
        },
      };

      let res = await fetch(
        `${SERVER_URL}/api/getCheckoutSession/${uid}`,
        options
      );
      res = await res.json();

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const cancelSaleAndSendArtwork = async () => {
    try {
      const params = {
        newOwner:
          auth.moreInfoCurrentUser?.provider === "firebase"
            ? TG_WALLET_ADDRESS
            : auth.moreInfoCurrentUser?.walletAddress,
        tokenId: tokenId,
      };

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };

      let res = await fetch(
        `${SERVER_URL}/api/cancelSaleAndSendArtwork`,
        options
      );

      res = await res.json();
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentPurchasedData = JSON.parse(
      localStorage.getItem("currentPurchasedData")
    );

    const checkoutSessionStatus = async () => {
      try {
        const res = await getCheckoutSession(
          currentPurchasedData?.checkoutSessionId
        );

        if (res?.id && res?.metadata.tokenId === tokenId) {
          if (success && res?.status === "complete") {
            await cancelSaleAndSendArtwork();

            if (auth.moreInfoCurrentUser?.provider === "firebase") {
              await updateArtworkOwner(
                currentPurchasedData.tokenId,
                TG_WALLET_ADDRESS,
                "firebase"
              );
            }

            if (auth.moreInfoCurrentUser?.provider === "moralis") {
              await updateArtworkOwner(
                currentPurchasedData.tokenId,
                auth.moreInfoCurrentUser?.walletAddress,
                "moralis"
              );
            }

            setOpenSuccessfulModal(true);
          }

          if (canceled && res?.status !== "complete") {
            await deleteReservation(0, currentPurchasedData?.tokenId);

            const artworkRef = doc(
              db,
              "artworks",
              currentPurchasedData?.tokenId
            );
            await updateDoc(artworkRef, {
              checkoutSessionId: deleteField(),
            });

            setOpenErrorModal(true);
          }
        }

        setOpenIsPendingModal(false);
        localStorage.removeItem("currentPurchasedData");
      } catch (error) {
        console.log(error);
      }
    };

    if (
      auth.currentUser &&
      (canceled || success) &&
      tokenId &&
      currentPurchasedData?.checkoutSessionId &&
      !currentPurchasedData?.soldOut
    ) {
      setOpenIsPendingModal(true);
      checkoutSessionStatus();
    }
  }, [canceled, success, tokenId, auth.currentUser]);

  const memodValue = useMemo(
    () => ({
      currentPurchasedArtwork,
      setCurrentPurchasedArtwork,
      openIsPendingModal,
      setOpenIsPendingModal,
      openSuccessfulModal,
      setOpenSuccessfulModal,
      openErrorModal,
      setOpenErrorModal,
      openReservedErrorModal,
      setOpenReservedErrorModal,
      termsAndConditions,
      setTermsAndConditions,
      termsError,
      setTermsError,
      buyNow,
      buyArtworkByWallet,
      buyArtworkByCard,
    }),
    [
      currentPurchasedArtwork,
      openIsPendingModal,
      openSuccessfulModal,
      openErrorModal,
      openReservedErrorModal,
      termsAndConditions,
      termsError,
    ]
  );

  return (
    <purchaseContext.Provider value={memodValue}>
      {children}
    </purchaseContext.Provider>
  );
}

export default function usePurchase() {
  return useContext(purchaseContext);
}
